import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import LogoLinks from '../components/LogoLinks';
import Testimonials from '../components/Testimonials';
import AutoPlaySilentVideo from '../components/AutoPlaySilentVideo';

import videoMP4NoAudio from '../assets/hero_videoMP4-noaudio.mp4';

const container = {
   hidden: { opacity: 0 },
   show: {
      opacity: 1,
      transition: {
         staggerChildren: 0.75,
      },
   },
};

const item = {
   hidden: { opacity: 0, y: 35 },
   show: {
      opacity: 1,
      y: 0,
      transition: {
         duration: 0.65,
      },
   },
};

const IndexPage = () => {
   return (
      <Layout>
         <Seo title='NYC Catering' />
         <header className='w-full select-none'>
            <div className='relative'>
               <div className='relative h-full'>
                  <div className='absolute inset-0'>
                     <AutoPlaySilentVideo className='video-player object-cover w-full h-full object-center' video={videoMP4NoAudio} />
                     <div aria-hidden='true' className='opacity-30 md:opacity-50 absolute inset-0 bg-black' />
                  </div>
                  <div className=' relative mx-auto max-w-7xl px-4 pt-96 text-center sm:px-6 lg:px-8 pb-24 '>
                     <h1 className='mt-12 text-5xl font-hanley-script relative sm:text-6xl lg:text-8xl'>
                        <span className='block header mb-2'>Catered Events, </span>
                        <span className='block header'>Celebrations & Gatherings</span>
                     </h1>
                     <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1, delay: 1 }}
                        viewport={{ once: true }}
                        className='mt-12'
                     >
                        <Link
                           to='/contact'
                           className='hidden md:inline duration-300 proxima ease-in-out uppercase transition  text-base hover:bg-white font-bold border-2 z-50 border-brand md:px-8 md:py-4 py-2 px-4 my-6 tracking-widest text-brand'
                        >
                           Let's Plan Something
                        </Link>
                     </motion.div>
                  </div>
               </div>
            </div>
         </header>
         <Philosophy />
         <ImageGrid />
         <Testimonials />
         <LogoLinks />
      </Layout>
   );
};

const Philosophy = () => {
   return (
      <section className='relative w-full py-14 overflow-hidden'>
         <div className='absolute inset-0'>
            <StaticImage src='../images/slate.jpg' alt='Slate background' quality='50' className='h-full w-full object-cover' />
            <div aria-hidden='true' className='opacity-60 md:opacity-80 absolute inset-0 bg-black' />
         </div>
         <div className='relative flex items-center justify-center py-2 w-full mx-auto'>
            <StaticImage
               src='../images/Logo-Just-A-Nibble-Utensils-White.png'
               className='opacity-50 '
               placeholder='tracedSVG'
               alt='Just a Nibble Utensils Icon'
               quality={75}
               height={125}
            />
            <motion.p
               initial={{ opacity: 0 }}
               whileInView={{ opacity: 1 }}
               transition={{ duration: 1, delay: 1 }}
               viewport={{ once: true }}
               className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand font-hanley-sans text-center tracking-widest uppercase font-boldest z-20'
            >
               Our Philosophy
            </motion.p>
         </div>
         <div className='relative py-14'>
            <motion.h2
               initial={{ y: 10, opacity: 0 }}
               whileInView={{ y: 0, opacity: 1 }}
               transition={{ duration: 1 }}
               viewport={{ once: true }}
               className='font-hanley-script text-white text-center text-5xl'
            >
               Welcome to Just a Nibble
            </motion.h2>
         </div>
         <div className='relative pb-8 md:pb-20 z-20 w-full proxima lg:text-base text-sm'>
            <div className='max-w-7xl mx-auto text-center md:text-left'>
               <div className='flex md:flex-row flex-col items-center justify-center gap-4 md:gap-12 w-full px-8 mx-auto'>
                  <div className=''>
                     <StaticImage
                        src='../images/Homepage-Just-A-Nibble-Philosophy.jpg'
                        alt='Chef preparing Chicken'
                        className='w-96 object-contain shadow-md'
                     />
                  </div>
                  <motion.div
                     variants={container}
                     initial='hidden'
                     animate='show'
                     className='prose space-y-4 proxima text-white leading-loose mt-8 md:mt-0'
                  >
                     <motion.p variants={item}>
                        Here at Just a Nibble NYC, we cater with a conscience. What does this mean? We put relationships first. We build meaningful
                        connections with vendors. We take time to get to know our clients. We treat our staff with the respect that their experience
                        and skills demand, so they can arrive at your event ready to provide the highest quality service. We know you’ll notice a
                        difference.
                     </motion.p>
                     <motion.p variants={item}>
                        Owned and operated by native New Yorkers with over twenty years of experience in high-end catering; we use fresh,
                        locally-sourced ingredients and are meticulous in our plating and presentation. We know how to cultivate the right atmosphere
                        in any of the city’s spaces. Whether your event is in one of New York’s most beloved and iconic venues or in your own living
                        room, tell us what you have in mind and then leave it to us! We know just what to do.
                     </motion.p>
                  </motion.div>
               </div>
            </div>
         </div>
         <motion.hr
            initial={{ width: 0, opacity: 0 }}
            whileInView={{ width: 200, opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className='border-brand relative border z-50 rounded mx-auto my-3'
         />
      </section>
   );
};

const ImageGrid = () => {
   return (
      <section className='relative w-full '>
         <div className='grid-cols-2 md:grid-cols-4 grid w-full'>
            <div className='overflow-hidden'>
               <StaticImage
                  className='aspect-square'
                  quality={75}
                  placeholder='blurred'
                  alt='Steak'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-1.jpg'
               />
            </div>
            <div className='overflow-hidden'>
               <StaticImage
                  className='aspect-square'
                  quality={75}
                  placeholder='blurred'
                  alt='Charcuterie board'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-2.jpg'
               />
            </div>
            <div className='overflow-hidden'>
               <StaticImage
                  className='aspect-square'
                  quality={75}
                  placeholder='blurred'
                  alt='Meatball appetizer'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-3.jpg'
               />
            </div>
            <div className='overflow-hidden'>
               <StaticImage
                  className='aspect-square'
                  quality={75}
                  placeholder='blurred'
                  alt='Sliced Chicken Entree'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-4.jpg'
               />
            </div>
         </div>
         <div className='hidden md:grid grid-cols-4 w-full'>
            <div className='overflow-hidden'>
               <StaticImage
                  className='aspect-square'
                  quality={75}
                  placeholder='blurred'
                  alt='Lamb Chop Entree'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-5.jpg'
               />
            </div>
            <div className='overflow-hidden'>
               <StaticImage
                  className='aspect-square'
                  objectFit='cover'
                  placeholder='blurred'
                  alt='Filet Mignon, greens beans and scallop potatoes'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-9.jpg'
               />
            </div>
            <div className='overflow-hidden'>
               <StaticImage
                  className='aspect-square'
                  quality={75}
                  placeholder='blurred'
                  alt='Potatoe and Rosemary Side'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-7.jpg'
               />
            </div>
            <div className='overflow-hidden'>
               <StaticImage
                  className='aspect-square'
                  quality={75}
                  placeholder='blurred'
                  alt='Meatball appetizer'
                  src='../images/Homepage-Just-A-Nibble-Grid-Image-8.jpg'
               />
            </div>
         </div>
      </section>
   );
};

export default IndexPage;
